<template>
  <v-card class="elevation-0">
    <v-card-title>
      <div class="d-flex flex-column">
        <div class="subtitle-1 font-weight-bold">{{ title }}</div>
      </div>
      <v-spacer></v-spacer>

      <v-text-field
        v-on:keyup.enter="searchOnEnter"
        v-on:blur="searchOnEnter"
        append-icon="mdi-magnify"
        hint="NIP, Nama (tekan ENTER untuk mulai cari)"
        label="Cari data"
      ></v-text-field>
      <v-btn @click="showFilterDialog" icon fab color="blue" small class="ml-2">
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-card-title>

    <v-expand-transition v-if="false">
      <v-card
        class="ml-2 mr-2 elevation-0 grey lighten-4"
        v-show="showFilter === true"
      >
        <v-card-text class="green lighten-5 rounded-lg">
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                v-model="filterFdivisions"
                :items="itemsFDivision"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                multiple
                label="Dinas/OPD"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-btn color="primary" small @click="runExtendedFilter">
                Terapkan
                <v-icon small class="ml-1" color="green lighten-2"
                  >mdi-filter</v-icon
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-expand-transition>

    <v-expand-transition>
      <v-card
        class="ml-2 mr-2 elevation-0 green lighten-5 rounded-lg"
        v-show="showFilter === true"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="4" v-if="currentUser.organizationLevel === 'CORP' ">
              <v-autocomplete
                  solo-inverted
                v-model="filterFdivisions"
                :items="itemsFDivision"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                multiple
                label="Dinas/OPD"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                  solo-inverted
                  v-model="filterFtemplateReg"
                :items="itemsFTemplateReg"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Jenis Registrasi"
                multiple
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                  solo-inverted
                v-model="filterStatusUsulan"
                :items="itemsStatusUsulan"
                item-value="id"
                item-text="description"
                dense
                chips
                small-chips
                deletable-chips
                clearable
                label="Status Usulan"
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="2" sm="2" md="2">
              <v-btn  class="rounded-lg" color="primary" @click="runExtendedFilter">
                Terapkan
                <v-icon small class="ml-1" color="green lighten-2">mdi-filter</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-expand-transition>

    <v-data-table
      v-model="selectedItems"
      :single-select="!multiSelect"
      :show-select="multiSelect"
      :headers="headers"
      :items="ftRegistrasisFiltered"
      :page.sync="currentPage"
      :items-per-page="pageSize"
      hide-default-footer
      class="elevation-0"
      @page-count="totalTablePages = totalPaginationPages"
    >
      <template v-slot:top>
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
            v-model="multiSelect"
            :label="multiSelect ? 'Multi Select' : 'Single Select'"
            x-small
            disabled
          ></v-switch>
          <v-btn
            v-if="false"
            fab
            dark
            color="red accent-4"
            x-small
            :disabled="!multiSelect"
            class="mr-2"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-menu offset-y style="align-items: start">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab dark x-small color="blue" v-bind="attrs" v-on="on">
                <v-icon>mdi-view-grid</v-icon>
              </v-btn>
            </template>
            <v-list color="grey lighten-4" class="mr">
              <v-divider></v-divider>
            </v-list>
          </v-menu>
          <v-btn
            v-if="isDisableAddButton"
            fab
            dark
            color="green"
            x-small
            @click="showDialogNew"
            :disabled="multiSelect"
            class="ml-1"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.submitted`]="{ item }">
        <v-btn
          v-if="item.submitted === true"
          fab
          x-small
          dark
          medium
          color="success"
        >
          <v-icon>mdi-check</v-icon>
        </v-btn>

        <v-btn
          v-if="item.submitted === false"
          fab
          x-small
          dark
          medium
          color="red"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip :color="getColorStatusActive(item.statusActive)" dark small>
          {{ item.statusActive === true ? "Aktif" : "-" }}
        </v-chip>
      </template>

      <template v-slot:[`item.number`]="{ index }">
        {{ index + (currentPage - 1) * pageSize + 1 }}
      </template>

      <template v-slot:[`item.avatar`]="{ item }">
        <v-img
          :lazy-src="lookupImageUrlLazy(item)"
          :src="lookupImageUrl(item)"
          alt="avatar"
          width="70px"
          height="70px"
          class="ma-2 rounded"
        >
        </v-img>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div class="subtitle-1">
          {{ item.description }}
        </div>
        <div class="font-weight-bold">
          {{ item.nip }}
        </div>
      </template>

      <template v-slot:[`item.cekItem`]="{ item }">
        <div class="caption">
          {{ item }}
        </div>
      </template>

      <template v-slot:[`item.ftemplateRegBean`]="{ item }">
        <div class="blue--text font-weight-bold">
          {{ lookupFTemplateReg(item.ftemplateRegBean).description }}
        </div>
        <div class="grey--text font-weight-light">
          *{{ lookupFDivision(item.fdivisionBean).description }}*
        </div>
        <div>
          {{ item.periodeYear }}
        </div>
        <div>{{item.fdivisionBean}}</div>
      </template>

      <template v-slot:[`item.validasiStatus`]="{ item }">
        <div class="py-2">
          <v-chip
            dark
            small
            color="grey"
            v-if="
              item.submitted === false &&
              item.validasiStatus === 0 &&
              item.validasiReasonCode === 0
            "
            >Draft</v-chip
          ><v-chip
            dark
            small
            color="orange darken-1"
            v-else-if="
              item.submitted === true &&
              item.verifikatorBean === 0 &&
              item.validasiStatus === 0 &&
              item.validasiReasonCode === 0
            "
            >Menunggu Verifikasi</v-chip
          >
          <v-chip
            dark
            small
            color="blue-grey darken-1"
            v-else-if="
              item.submitted === true &&
              item.verifikatorBean === 0 &&
              item.validasiStatus === 0 &&
              item.validasiReasonCode === 1
            "
            >Menunggu Re-verifikasi</v-chip
          >
          <v-chip
            dark
            small
            color="primary"
            v-else-if="
              item.submitted === true &&
              item.verifikatorBean > 0 &&
              item.validasiStatus === 0 &&
              item.validasiReasonCode === 0
            "
            >Proses Verifikasi</v-chip
          >
          <v-chip
            dark
            small
            color="teal"
            v-else-if="
              item.submitted === true &&
              item.verifikatorBean > 0 &&
              item.validasiStatus === 0 &&
              item.validasiReasonCode === 1
            "
            >Proses Re-verifikasi</v-chip
          >
          <v-chip
            dark
            small
            color="red"
            v-else-if="item.validasiStatus !== 1"
            >{{
              item.submitted === false && item.validasiReasonCode === 1
                ? "Dikembalikan"
                : "Tidak Valid"
            }}</v-chip
          >
          <v-chip
            dark
            small
            color="success"
            v-else-if="item.validasiStatus === 1"
          >
            <v-icon class="mr-1">mdi-check-circle</v-icon>
            VALID
          </v-chip>

          <div
            class="mt-2 grey--text caption"
            v-if="item.submitted === false && item.validasiReasonCode === 1"
          >
            Berkas Tidak Sesuai
          </div>
          <div
            class="mt-2 grey--text caption"
            v-if="item.submitted === true && item.validasiReasonCode === 2"
          >
            Tidak Memenuhi Syarat
          </div>
          <div>
            {{ item.approvedReasons }}
          </div>
        </div>
      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        <div class="text-caption small">
          {{ lookupFDivision(item.fdivisionBean).description }}
        </div>
        <div>
          <v-chip :color="getColorStatusActive(item.statusActive)" dark x-small>
            {{ item.statusActive === true ? "Aktif" : "-" }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          @click="showDialogEdit(item)"
          icon
          :disabled="
            multiSelect ||
            item.submitted ||
            (!isDisableAddButton &&
              item.validasiStatus === 0 &&
              item.validasiReasonCode === 0)
          "
        >
          <v-icon small color="warning"> mdi-pencil </v-icon>
        </v-btn>
        <v-btn
          v-if="true"
          @click="deleteDialogShow(item)"
          icon
          :disabled="multiSelect || item.submitted"
        >
          <v-icon small color="red accent-4"> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col cols="4" md="2" sm="2">
          <v-select
            v-model="pageSize"
            :items="pageSizes"
            label="Items per page"
          ></v-select>
        </v-col>
        <v-col cols="10" md="9" sm="8" align="right">
          <v-pagination
            v-model="currentPage"
            :length="totalPaginationPages"
            total-visible="8"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>

    <delete-dialog
      ref="refDeleteConfirmDialog"
      @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
    ></delete-dialog>
  </v-card>
</template>
  
<script>
import FtRegistrasiService from "@/services/apiservices/ft-registrasi-service";

import DeleteDialog from "@/components/utils/DeleteConfirmDialog";
import FileService from "@/services/apiservices/file-service";
import FtRegistrasiFilter from "@/models/payload/ft-registrasi-filter";

import FJenisJabatanService from "@/services/apiservices/f-jenis-jabatan-service";
import FJenisProsedurService from "@/services/apiservices/f-jenis-prosedur-service";
import FJenisKenaikanPangkatService from "@/services/apiservices/f-jenis-kenaikan-pangkat-service";
import FJenisKartuService from "@/services/apiservices/f-jenis-kartu-service";
import FDivisionService from "@/services/apiservices/f-division-service";
import FTemplateRegService from "@/services/apiservices/f-template-reg-service";
import FJenisDokumenService from "@/services/apiservices/f-jenis-dokumen-service";
import FTemplateRegDokumenService from "@/services/apiservices/f-template-reg-dokumen-service";
import FtRegistrasi from "@/models/ft-registrasi";

export default {
  props: {
    stepperPage: Number,
    // itemFTemplateReg: Object,
  },
  components: { DeleteDialog },
  data() {
    return {
      title: "REG LAYANAN",

      itemFTemplateReg: Object,

      snackbar: false,
      snackBarMesage: "",

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      showFilter: true,
      filterFdivisions: [],
      filterFtemplateReg: [],

      filterStatusUsulan: 0,
      itemsStatusUsulan: [
        { id: 1, description: "1. Menunggu Verifikasi" },
        { id: 2, description: "2. Proses Verifikasi" },
        { id: 3, description: "3. Terverifikasi/Valid" },
        { id: 4, description: "4. Dikembalikan/Tidak Valid" },
        { id: 5, description: "5. Approve" },
      ],

      search: "",
      headers: [
        {
          text: "No",
          value: "number",
          width: "8%",
          sortable: false,
        },
        // {
        //   text: "",
        //   align: "center",
        //   sortable: false,
        //   value: "avatar",
        // },
        { text: "NIP & Nama PNS", value: "description" },
        { text: "USULAN/PENGAJUAN", value: "ftemplateRegBean" },

        { text: "Tanggal Usulan", value: "trDate" },
        {
          text: "Status Usulan",
          value: "validasiStatus",
          align: "center",
          sortable: false,
        },

        { text: "Actions", value: "actions", sortable: false },
      ],

      formMode: "",
      itemSelectedIndex: -1,
      itemSelected: "",
      ftRegistrasis: [],
      ftemplateRegBean: {},

      itemsFDivision: [],
    };
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFtRegistrasi();
      },
    },
    pageSize: {
      handler: function (value) {
        const refreshData = this.currentPage === 1;
        this.currentPage = 1;
        if (refreshData) {
          console.log("Change PageSize " + value);
          this.fetchFtRegistrasi();
        }
        // console.log("page size = " + value + " >> " + this.totalPages)
      },
    },
  },
  computed: {
    isDisableAddButton() {
      return !(
        new Date(this.ftemplateRegBean.openFrom).getTime() >
          new Date().getTime() ||
        new Date(this.ftemplateRegBean.openTo).getTime() < new Date().getTime()
      );
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    ftRegistrasisFiltered() {
      return this.ftRegistrasis;
    },
    itemsFTemplateReg: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFTemplateReg;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFTemplateReg",
          value
        );
      },
    },
  },
  methods: {
    saveDataNew(itemFromRest) {
      this.itemSelected = itemFromRest;
      this.closeDialog();
      /**
       * Setiap data baru harus terlihat
       */

      this.fetchFtRegistrasi();
    },
    saveDataEdit(item) {
      this.itemSelected = item;
      try {
        //Kalau Create terus uplaod image duluan tidak bisa
        Object.assign(
          this.ftRegistrasis[this.itemSelectedIndex],
          this.itemSelected
        );
      } catch (e) {
        console.log(e);
      }
      this.closeDialog();
    },
    showFilterDialog() {
      this.showFilter = !this.showFilter;
    },

    runExtendedFilter() {

      const hasAdminOrApproval = ['ROLE_ADMIN', 'ROLE_APPROVAL', 'ROLE_VERIFIKATOR'].some(role => this.currentUser.roles.includes(role));
      const extendedFilter = new FtRegistrasiFilter();
      extendedFilter.ftemplateRegIds = this.filterFtemplateReg;
      if (extendedFilter.ftemplateRegIds.length===0 && hasAdminOrApproval && this.currentUser.organizationLevel === "DIV") {
        extendedFilter.ftemplateRegIds = this.itemsFTemplateReg.map(
          (item) => item.id
        );
      }

      extendedFilter.fdivisionIds = this.filterFdivisions;
      if (extendedFilter.fdivisionIds.length === 0 && hasAdminOrApproval) {
        extendedFilter.fdivisionIds = this.itemsFDivision.map(
          (item) => item.id
        );
      }

      extendedFilter.validasiStatusIds = [];
      if (this.filterStatusUsulan) {
        extendedFilter.statusUsulanBean = this.filterStatusUsulan;
      }

      extendedFilter.pageNo = this.currentPage;
      extendedFilter.pageSize = this.pageSize;
      extendedFilter.sortBy = "id";
      extendedFilter.order = "DESC";
      extendedFilter.search = this.search;
      extendedFilter.submittedOnly = false;

      extendedFilter.city = "";

      // console.log(JSON.stringify(extendedFilter, null, 2));

      FtRegistrasiService.getPostAllFtRegistrasiContainingExt(
        extendedFilter
      ).then(
        (response) => {
          const { items, totalPages } = response.data;
          this.ftRegistrasis = items;
          this.totalPaginationPages = totalPages;

          // console.log(JSON.stringify(this.ftRegistrasis, null, 2));
        },
        (error) => {
          console.log(error);
        }
      );
    },

    searchOnEnter(event) {
      if (this.search !== event.target.value) {
        this.currentPage = 1;
        this.search = event.target.value;
        this.fetchFtRegistrasi();
      }
    },

    fetchParent() {
      const hasAdminOrApproval = ['ROLE_ADMIN', 'ROLE_APPROVAL', 'ROLE_VERIFIKATOR'].some(role => this.currentUser.roles.includes(role));
      if (! hasAdminOrApproval && this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
          (response) => {
            this.itemsFDivision = [response.data];
          },
          (error) => {
            console.log(error.response);
          }
        );
      } else {
        FDivisionService.getAllFDivision().then(
          (response) => {
            this.itemsFDivision = response.data;
          },
          (error) => {
            console.log(error.response);
          }
        );
      }

      FTemplateRegService.getAllFTemplateReg().then(
        (response) => {
          if (hasAdminOrApproval && this.currentUser.organizationLevel === "DIV") {
            this.itemsFTemplateReg =  response.data.filter(
              (item) => item.fdivisionBean === this.currentUser.fdivisionBean
            )
          }else {
            this.itemsFTemplateReg = response.data;
          }

        },
        (error) => {
          console.log(error.response);
        }
      );
      FJenisJabatanService.getAllFJenisJabatan().then(
        (response) => {
          // console.log(response.data);
          this.itemsFJenisJabatan = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
      FJenisProsedurService.getAllFJenisProsedur().then(
        (response) => {
          // console.log(response.data);
          this.itemsFJenisProsedur = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
      FJenisKenaikanPangkatService.getAllFJenisKenaikanPangkat().then(
        (response) => {
          // console.log(response.data);
          this.itemsFJenisKenaikanPangkat = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
      FJenisKartuService.getAllFJenisKartu().then(
        (response) => {
          // console.log(response.data);
          this.itemsFJenisKartu = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
      FJenisDokumenService.getAllFJenisDokumen().then(
        (response) => {
          // console.log(response.data);
          this.itemsFJenisDokumen = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
    },
    fetchFTemplateRegDokumen(item) {
      FTemplateRegDokumenService.getAllFTemplateRegDokumenByParent(
        item.id
      ).then(
        (response) => {
          // console.log(response.data);
          this.itemsFTemplateRegDokumen = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
    },

    fetchFtRegistrasi() {
      this.runExtendedFilter();
    },

    showDialogNew() {
      this.itemSelectedIndex = -1;
      const itemModified = Object.assign({}, new FtRegistrasi());

      itemModified.ftemplateRegBean = this.ftemplateRegBean.id;
      itemModified.fdivisionBean = this.currentUser.fdivisionBean;

      FtRegistrasiService.createFtRegistrasi(itemModified).then((response) => {
        // console.log(JSON.stringify(response.data))
        this.$router.push("/registrasi-layanan-edit/" + response.data.id);
      });
    },
    showDialogEdit(item) {
      this.$router.push("/registrasi-layanan-edit/" + item.id);
    },

    getColorStatusActive(trueFalse) {
      if (trueFalse === true) return "green";
      else if (trueFalse === false) return "gray";
      else return "gray";
    },

    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupFTemplateReg(ftemplateRegBean) {
      const str = this.itemsFTemplateReg.filter(
        (x) => x.id === ftemplateRegBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

    lookupImageUrl(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/account_icon.png";
      } else {
        return FileService.image_url_low(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/account_icon.png";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },
    deleteDialogShow(item) {
      this.itemSelectedIndex = this.ftRegistrasis.indexOf(item);
      this.itemSelected = Object.assign({}, item);
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(
        this.itemSelectedIndex,
        item.username
      );
    },
    deleteItemConfirmedSingleSelect(index) {
      console.log("delete SingleItem: " + index);

      const deletedItem = this.ftRegistrasis[this.itemSelectedIndex];
      FtRegistrasiService.deleteFtRegistrasi(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.ftRegistrasis.splice(this.itemSelectedIndex, 1);
          this.$refs.refDeleteConfirmDialog.setDialogState(false);
          /**
           * #############
           * khusus ini harus menghapus seluruh FtRegistrasiFile Beriktu File Dokumen dan Datanya
           * @@@@@@@@@@@@
           */
        },
        (error) => {
          console.log(error);
          this.$refs.refDeleteConfirmDialog.setDialogState(false);
        }
      );
    },
  },
  mounted() {
    this.fetchParent();
    if (!this.currentUser) {
      this.$router.push("/login");
    } else {
      const id = this.$route.params.id;
      FTemplateRegService.getFTemplateRegById(id).then(
        (response) => {
          const hasAdminOrApproval = ['ROLE_ADMIN', 'ROLE_APPROVAL', 'ROLE_VERIFIKATOR'].some(role => this.currentUser.roles.includes(role));
          if (hasAdminOrApproval && this.currentUser.organizationLevel === "DIV") {
            if (response.data.fdivisionBean === this.currentUser.fdivisionBean) {
              this.ftemplateRegBean = response.data
            }
          }else {
            this.ftemplateRegBean = response.data;
          }
          this.title = this.ftemplateRegBean.description.toUpperCase();

          if (this.ftemplateRegBean){
            this.filterFtemplateReg = [this.ftemplateRegBean.id]
            this.fetchFtRegistrasi();
          }

        },
        (error) => {
          console.log(error.response);
        }
      );
    }
  },
};
</script>

<style scoped>
</style>